import { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { StandardProposalConstant } from "./StandardProposalConstant";

const STANDARD_PROPOSAL_CONSTANT = StandardProposalConstant.Table;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
export const INITIAL_STD_PROPOSAL_COPY_TO_NEW_PROPOSAL_COL_DEF: any[] = [
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.COMPANY_CODE,
        field: 'companyCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        // pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        filter: true,
        width: 200,
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.EFFECTIVE_DATE,
        field: 'effectiveDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "date"
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.EXPIRY_DATE,
        field: 'expiryDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "date"
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.OPERATING_TML,
        field: 'operatingTml',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 160,
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.CURRENCY,
        field: 'currencyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 120,
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.PROPOSAL_BY,
        field: 'proposalCreatedByMethod',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.REMARKS,
        field: 'remarks',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 380,
    },

]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}
