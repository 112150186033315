import { SelectionChangedEvent } from "ag-grid-community";
import { INITIAL_STANDARD_PROPOSAL_TARIFF_ITEM_TIER_COL_DEF, transferRowData } from "presentation/constant/StandardProposal/StandardProposalTariffItemTierColumnDefinition";
import { useStandardProposalVM } from "presentation/hook/StandardProposal/useStandardProposalVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useStandardProposalTracked } from "presentation/store/StandardProposal/StandardProposalProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";
import TariffItemHeaderBar from "./TariffItemHeaderBar";
import TariffItemISTablePanel from "./TariffItemISTablePanel";


const TariffItemTierTablePanel: React.FC = () => {
    const [standardProposalState] = useStandardProposalTracked();
    const standardProposalVM = useStandardProposalVM();
    const { currentTariffItem, currentTariffItemTier, isAddIs, isAddTier, isEditIs, isEditTier, tariffItemTierSelectedRows } = standardProposalState;
    let gridRef: any = useRef();
    const messageBarVM = useMessageBarVM();

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        standardProposalVM.updateSelectedTariffItemTierRows(selectedRows);
    }, [standardProposalVM])

    const handleAdd = useCallback(() => {
        if ("FINALIZED" === standardProposalState.currentSelectedRow.status || "PROPOSED" === standardProposalState.currentSelectedRow.status) {
            messageBarVM.showError("Standard Proposal status is \"PROPOSED\" or \"FINALIZED\", cannot Add.");
            return;
        }
        standardProposalVM.onAddTier();
    }, [messageBarVM, standardProposalState.currentSelectedRow.status, standardProposalVM]);

    const handClose = useCallback(() => {
        standardProposalVM.onTariffItemTierCloseClick();
    }, [standardProposalVM]);


    const handEdit = useCallback(() => {
        if ("FINALIZED" === standardProposalState.currentSelectedRow.status || "PROPOSED" === standardProposalState.currentSelectedRow.status) {
            messageBarVM.showError("Standard Proposal status is \"PROPOSED\" or \"FINALIZED\", cannot Edit.");
            return;
        }
        if (currentTariffItemTier?.cycle == null) {
            messageBarVM.showError("Please select a Tier record.");
            return;
        }
        standardProposalVM.onTariffItemTierEidtClick();
    }, [currentTariffItemTier?.cycle, messageBarVM, standardProposalState.currentSelectedRow.status, standardProposalVM]);

    const handDelete = useCallback(() => {
        if ("FINALIZED" === standardProposalState.currentSelectedRow.status || "PROPOSED" === standardProposalState.currentSelectedRow.status) {
            messageBarVM.showError("Standard Proposal status is \"PROPOSED\" or \"FINALIZED\", cannot Delete.");
            return;
        }
        if (tariffItemTierSelectedRows.length === 0) {
            messageBarVM.showError("Please select a record.");
            return;
        }
        if (currentTariffItem.tierList && (currentTariffItem.tierList.length === 1 || currentTariffItem.tierList.length === tariffItemTierSelectedRows.length)) {
            messageBarVM.showError("At least one tier is required.");
            return;
        }
        standardProposalVM.onTariffItemTierDeleteClick(tariffItemTierSelectedRows);
    }, [currentTariffItem.tierList, messageBarVM, standardProposalState.currentSelectedRow.status, standardProposalVM, tariffItemTierSelectedRows]);

    const memoBtns = useMemo(() => {

        const hdrBtns = [];
        if (!(isAddTier || isEditTier || isAddIs || isEditIs)) {
            hdrBtns.push({
                id: 'onEditButton',
                icon: 'Icon-pen',
                title: 'Edit'
            })
            hdrBtns.push({
                id: 'onDeleteButton',
                icon: 'Icon-trash',
                title: 'Delete'
            })
        }

        hdrBtns.push(
            {
                id: 'onCloseButton',
                icon: 'Icon-cross',
                title: 'Close'
            }
        )

        return hdrBtns
    }, [isAddIs, isAddTier, isEditIs, isEditTier])

    const memoStandardProposalTariffItemTierTable = useMemo(() => {

        return (
            <NbisTable
                id='standard-proposal-detail-table'
                isNewColumnSetting={true}
                columns={INITIAL_STANDARD_PROPOSAL_TARIFF_ITEM_TIER_COL_DEF?.slice()}
                data={transferRowData(currentTariffItem.tierList ?? [])}
                headerActionButtons={memoBtns}
                onEditButton={handEdit}
                onDeleteButton={handDelete}
                onCloseButton={handClose}
                showPaginator={false}
                editable={false}
                showUploadIcon={false}
                showAddIcon={!(isAddTier || isEditTier || isAddIs || isEditIs)}
                onAddClick={handleAdd}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                rowSelection={"multiple"}
                isRowHighligted={true}
                handleSelectionChanged={handleSelectionChange}
                gridHeight="customHeight"
                customHeight="calc(100vh - 615px)"
                ref={gridRef}
            />
        );
    }, [currentTariffItem.tierList, handClose, handDelete, handEdit, handleAdd, handleSelectionChange, isAddIs, isAddTier, isEditIs, isEditTier, memoBtns])

    const memoTariffItemHeaderTable = useMemo(() => {
        return <TariffItemHeaderBar />
    }, [])

    const memoTariffItemISTable = useMemo(() => {
        return <TariffItemISTablePanel />
    }, [])

    // const memoTariffItemIsEditPanel = useMemo(() => {
    //     return <TariffItemIsEditPanel />
    // }, [])

    return <>
        <div className="main-comp-wrapper">
            {/*<div style={{ width: '100%', height: '100%', maxHeight: '80%' }}*/}
            <div style={{ height: '8%' }}>
                {memoTariffItemHeaderTable}
            </div>
            <div style={{ height: '42%' }}>
                <TableWrapper>
                    {memoStandardProposalTariffItemTierTable}
                </TableWrapper>
            </div>
            <div style={{ height: '50%' }}>
                {memoTariffItemISTable}
            </div>
            {/* {<div className={`child-div${(isAddIs || isAddTier || isEditIs || isEditTier) ? " child-div-std-tariff-item-edit-expanded " : ""}`}>
                    {memoTariffItemIsEditPanel}
                </div>} */}
            {/*</div>*/}
        </div>
    </>;
}

export default memo(TariffItemTierTablePanel);
