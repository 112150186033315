import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useStandardProposalVM } from "presentation/hook/StandardProposal/useStandardProposalVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useStandardProposalTracked } from "presentation/store/StandardProposal/StandardProposalProvider";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { HPHButton, Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import StandardProposalTariffItemTablePanel from "./StandardProposalTariffItemTablePanel";
import TariffItemCopyRightPanel from "./TariffItemCopyRightPanel";
import TariffItemTierTableRightPanel from "./TariffItemTierTableRightPanel";

const TariffItemRightPanel = () => {
    const [standardProposalState] = useStandardProposalTracked();
    const standardProposalVM = useStandardProposalVM();
    const messageBarVM = useMessageBarVM();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [initScreen, setInitScreen] = useState<boolean>(true);
    const { isShowCopyPanel, deleteItemList, isShowTariffItemTierPanel, standardProposalItemList, searchStatus, tariffCodeIds, isUpdateOrAdd, currentTariffItem, isAddIs, isAddTier, isEditIs, isEditTier, isAddTariffItem } = standardProposalState;

    const memoTariffItemCopyRightPanel = useMemo(() => {
        return <div style={{ marginLeft: "15px" }}><TariffItemCopyRightPanel /></div>
    }, [])

    const memoTariffItemTable = useMemo(() => {
        return <div style={{ marginLeft: "15px" }}><StandardProposalTariffItemTablePanel /></div>
    }, [])
    const memoTariffItemTierTable = useMemo(() => {
        return <div style={{ marginLeft: "15px" }}><TariffItemTierTableRightPanel /></div>
    }, [])


    const isDisable = useCallback(() => {
        if ((!(isAddIs || isAddTier || isEditIs || isEditTier) && (isAddTariffItem || isUpdateOrAdd) && isShowTariffItemTierPanel)
            || (deleteItemList && deleteItemList.length > 0 && !isShowTariffItemTierPanel)) {
            return false
        }
        return true;
    }, [deleteItemList, isAddIs, isAddTariffItem, isAddTier, isEditIs, isEditTier, isShowTariffItemTierPanel, isUpdateOrAdd]);

    const handleSave = useCallback(async () => {
        if (isAddTariffItem) {
            if (!currentTariffItem.tariffType) {
                messageBarVM.showError("Tariff Type is mandatory.");
                return;
            }
            if (!currentTariffItem.tariffCode) {
                messageBarVM.showError("Tariff Code is mandatory.");
                return;
            }
        }
        if (standardProposalItemList && standardProposalItemList.length > 0) {
            if (!currentTariffItem.key && standardProposalItemList?.some(item =>
                item.tariffType === currentTariffItem.tariffType &&
                item.tariffCode === currentTariffItem.tariffCode &&
                (item.forwarderCode ? item.forwarderCode : "") === currentTariffItem.forwarderCode
            )) {
                messageBarVM.showError("Duplicate record found, please check the key: Tariff Type, Tariff Code, Forwarder Code.");
                return;
            }
        }
        if (isUpdateOrAdd) {
            // if(currentTariffItem.tierList && currentTariffItem.tierList.length === 0){
            //     messageBarVM.showError("Must add one tariff item tier at least.");
            //     return;
            // }
            // standardProposalVM.onShowLoading();
            setIsLoading(true);
            let tempDeleteItemList = !isShowTariffItemTierPanel ? deleteItemList : [];
            await standardProposalVM.onSaveTariffItem(standardProposalState.currentSelectedRow, currentTariffItem, standardProposalItemList, tariffCodeIds, tempDeleteItemList).then(async (data) => {
                if (data.code !== "200") {
                    messageBarVM.showError(data.data);
                    standardProposalVM.onHideLoading();
                    return;
                } else {
                    if (searchStatus !== "") {
                        await standardProposalVM.onGroupButtonClick(searchStatus);
                    } else {
                        //await standardProposalVM.searchStandardProposal(searchCriteria);
                        await standardProposalVM.onTariffItemTierCloseClick();
                        await standardProposalVM.searchStandardProposalItem(standardProposalState.currentSelectedRow).then((data) => {
                            setIsLoading(false);
                        }).catch(error => {
                            setIsLoading(false);
                        }).finally(() => {
                            setIsLoading(false);
                        })
                    }
                }
            });
        }
        standardProposalVM.onHideLoading();

    }, [isAddTariffItem, standardProposalItemList, isUpdateOrAdd, standardProposalVM, currentTariffItem, messageBarVM, isShowTariffItemTierPanel, deleteItemList, standardProposalState.currentSelectedRow, tariffCodeIds, searchStatus]);

    useEffect(() => {
        const initialScreen = async () => {
            if (!initScreen) return;
            setIsLoading(true);
            await Promise.allSettled([
                standardProposalVM.searchStandardProposalItem(standardProposalState.currentSelectedRow)
            ]).then(data => {
                setIsLoading(false);
                setInitScreen(false);
            }).catch(e => {
                setIsLoading(false);
                setInitScreen(false);
            }).finally(() => {
                setIsLoading(false);
                setInitScreen(false);
            })
        }

        initialScreen();
    }, [initScreen, standardProposalState.currentSelectedRow, standardProposalVM]);

    return <>
        <div className={`main-comp-wrapper${!isShowTariffItemTierPanel ? '' : ' im-hide-side-form-draggable'}`}>
            {<div className='empty-form'>
                {isLoading && <Loader Indicator="Spinner" size="Medium" />}
            </div>}
            <div style={{ width: '100%', height: '100%', maxHeight: '85%' }}>
                <SliderPanel
                    isOpen={isShowTariffItemTierPanel}
                    draggable={true}
                    leftSectionWidth={isShowTariffItemTierPanel ? "30%" : "100%"}
                    rightSectionWidth={isShowTariffItemTierPanel ? "70%" : "0%"}
                    leftChildren={memoTariffItemTable}
                    rightChildren={isShowTariffItemTierPanel ? (isShowCopyPanel ? memoTariffItemCopyRightPanel : memoTariffItemTierTable) : memoTariffItemTierTable}
                />
            </div>

            <div style={{ paddingRight: '20px', visibility: !isDisable() ? 'visible' : 'hidden', height: '100%', maxHeight: '15%', marginTop: '20px' }} >
                <SidebarActionBar>
                    {<HPHButton disabled={isDisable()} label={WorkspaceConstant.Common.BUTTON_SAVE} size={'Small'} theme={'Primary'} onClick={handleSave} />}
                    {/* {<HPHButton disabled={false} label={WorkspaceConstant.Common.BUTTON_SAVE} size={'Small'} theme={'Primary'} onClick={handleSave} />} */}
                </SidebarActionBar>
            </div>
        </div>
    </>
}

export default memo(TariffItemRightPanel);